import { useState } from 'react';
import './App.css';
import { Button, Grid2 as Grid, TextField } from '@mui/material';
import barb from './barb.png';
import { upperFirst } from 'lodash';
import { Casino } from '@mui/icons-material';


function App() {
  const initialListOfFood = [
    'chicken vesuvio',
    'Spinach feta pasta',
    'Apricot chicken',
    'Flank steak',
    'Steak',
    'Burgers/hot dog',
    'Chicken dumplings',
    'Thai peanut noodles',
    'Salmon',
    'Spaghetti casserole ',
    'Teriyaki chicken',
    'Asian chicken salad',
  ];

  const stringFoodList = initialListOfFood.map((item)=> upperFirst(item)).join('\n');
  const [foodList, setFoodList] = useState(stringFoodList);
  const [selectedMeal, setSelectedMeal] = useState("");

  function randomlyGetMeal() {
    const foodListArray = foodList.split('\n');
    let newMeal = selectedMeal;
    while (newMeal === selectedMeal){
      newMeal = foodListArray[Math.floor(Math.random()*foodListArray.length)];
    }
    setSelectedMeal(newMeal);
  }

  return (
    <Grid container spacing={2} className="App">
      <Grid size={12} className="titleContainer">
      <div className="barbImageContainer"><img className="barbImage" src={barb} alt="Barb standing at a white marble counter"/></div>
        <div>Welcome to Barb Roulette!</div>
        <div>Roll the dice to select what we're having for dinner this week!</div>

      </Grid>
      <Grid size={6}>
        <Button variant="contained" onClick={() => randomlyGetMeal()}><Casino/></Button>
      </Grid>
      <Grid size={6}>
        { selectedMeal }
      </Grid>
        <Grid size={12} className="foodListContainer">
         <TextField
          id="food-list"
          className="foodListContainer"
          label="Food List"
          multiline
          value={foodList}
          onChange={(event) => setFoodList(event.target.value)}
        />
        </Grid>
      </Grid>
  );
}

export default App;
